<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>助教管理</el-breadcrumb-item>
            <el-breadcrumb-item>助教列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="20">
                    <el-input placeholder="请输入助教编号/昵称/手机/用户名" clearable v-model="queryInfo.query" @clear="getList()" style="width: 350px;">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="是否审核" @change="getList()"  style="margin-left: 10px">
                        <el-option label="已审" value="1"></el-option>
                        <el-option label="未审" value="0"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <div style="font-size:12px;color:#ccc;line-height: 40px;">( <i class="el-icon-info" style="color:#ff0000"></i>为用户申请提现标识 )</div>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle">
                <el-table-column label="ID" type="index"  width="50" fixed></el-table-column>
                <el-table-column label="助教编号" prop="bh" width="80"></el-table-column>
                <el-table-column label="助教昵称" prop="nickname" width="120"></el-table-column>
                <el-table-column label="用户名" prop="username" width="120"></el-table-column>
                <el-table-column label="头像" prop="avatar" width="60">
                    <template slot-scope="scope">
                        <div v-if="scope.row.avatar">
                            <el-popover placement="bottom"  width="310" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL_Front+'static/'+scope.row.avatar" mode="cover"  style="width:300px;height: 300px;border-radius: 5px;"/>
                                </div>
                                <img  slot="reference" :src="imgURL_Front+'static/'+scope.row.avatar" style="height: 30px;width: 30px;border-radius: 50%;">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="健身地点" prop="storename" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="联系方式" prop="telephone" width="120"></el-table-column>
                <el-table-column label="价格" prop="price" width="100">
                    <template slot-scope="scope" v-if="scope.row.price">
                        ￥{{scope.row.price}}/小时
                    </template>
                </el-table-column>
                <el-table-column label="展示照片" prop="photos" width="100">
                    <template slot-scope="scope">
                        <el-image 
                            style="width: 30px;vertical-align:middle;"
                            :src="require('@/assets/image/preview.png')"
                            :preview-src-list="scope.row.photos">
                        </el-image><span style="color: #ccc;">（{{ scope.row.photos.length }}）</span>
                    </template>
                </el-table-column>
                <el-table-column label="审核" prop="ischeck" width="80">
                    <template  slot-scope="scope">
                        <el-switch active-value="1" inactive-value="0" v-model="scope.row.ischeck" @change="Checked(scope.row.id,scope.row.ischeck,scope.row.username)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="余额" width="80">
                    <template  slot-scope="scope">
                        ￥{{ scope.row.account }}
                        <el-tooltip class="item" effect="dark" content="此用户申请提现" placement="top">
                            <i v-if="scope.row.tx_check === '0' " class="el-icon-info"  style="color:#ff0000"></i>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="预约记录"  width="80">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-connection" size="mini" @click="YuYue(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
                <el-table-column label="提现记录"  width="80">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="warning" icon="el-icon-sort" size="mini" @click="TiXian(scope.row.username)"></el-button>
                    </template>
                </el-table-column>
                
                <el-table-column label="申请日期" prop="adddate"></el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--提现记录对话框-->
            <el-dialog title="提现记录" :visible.sync="txDialogVisible" width="60%" >
                <el-card>
                    <!-- {{this.txlist0}} -->
                    <el-table :data="txlist0"  :key="txlist0.id" style="width: 100%"  size="small" height="50" :show-header="false" >
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="reg_time"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="warning">待审</el-tag>
                             </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <!--作用域插槽-->
                            <template slot-scope="scope">
                                <!-- {{scope.row}} -->
                                <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.sign" @change="TixianChecked(scope.row.id)"></el-switch>  <span style="color:#ff0000">审核</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <br>
                <el-card>
                    <el-table :data="txlist1"  :key="txlist1.id" style="width: 100%" stripe size="small" height="300">
                        <el-table-column label="ID" type="index"></el-table-column>
                        <el-table-column label="提现单号" prop="orderno"></el-table-column>
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="adddate"></el-table-column>
                        <el-table-column label="审核时间" prop="checktime"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="success">已审</el-tag>
                             </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-dialog>
            <!--提现记录对话框-->
            <!--预约记录对话框-->
            <el-dialog title="预约记录" :visible.sync="yyDialogVisible" width="60%" >
                <el-card>
                    <el-table :data="yylist"  :key="yylist.id" style="width: 100%" stripe size="small" height="300">
                        <el-table-column label="ID" type="index"></el-table-column>
                        <el-table-column label="预约单号" prop="orderno"  width="150"></el-table-column>
                        <el-table-column label="预约用户" prop="username"  width="120"></el-table-column>
                        <el-table-column label="订单金额" prop="total"  width="100">
                            <template slot-scope="scope">
                            ￥{{scope.row.total}}
                        </template>
                        </el-table-column>
                        <el-table-column label="预约门店" prop="store"></el-table-column>
                        <el-table-column label="预约时间" prop="reserverdate"  width="200">
                            <template slot-scope="scope">
                                {{scope.row.reserverdate}} {{scope.row.reservertime}}
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" prop="status">
                            <template slot-scope="scope">
                                <el-link v-if="scope.row.status==1" type="success">已支付</el-link>
                                <el-link v-if="scope.row.status==2" type="warning">服务中</el-link>
                                <el-link v-if="scope.row.status==3" type="danger">已完成</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-dialog>
            <!--预约记录对话框-->
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            imgURL_Front:this.GLOBAL.imgURL_Front,
            queryInfo: {
                query: '',
                query1: '',
                pagenum: 1,
                pagesize: 10
            },
            txDialogVisible:false,
            yyDialogVisible:false,
            txlist0:[],
            txlist1:[],
            yylist:[],
            list: [],
            total: 0,
            srcList:[],
            admin_token:''
        }
    },
    created() {
         this.getList()
    },
    mounted(){
        this.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    methods:{
        async getList() {
           const { data: res} = await this.$http.get('coachlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                    this.list[index].id= res.data.list[index].pk;
                }
       },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async DelList(id){
                const confirmResult = await this.$confirm('是否要删除此订单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('orderdel/'+ id)
                    // console.log(res)
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getOrderList()
       },
       Preview(photos){
        console.log(photos);
       },
       async Checked(id,sign,username){
          const { data: res} = await this.$http.get('coachcheck/',{ params:{ id:id,sign:sign,username:username,admin_token:this.admin_token }})
          if(res.status === 0){
             this.$message({message: '审核通过！',type: 'success'}) 
             this.getList()
          }
          if(res.status === 1){
             this.$message({message: '审核取消！',type: 'warning'}) 
             this.getList()
          }
      },
      async TixianChecked(id){
        console.log(id);
        const confirmResult = await this.$confirm('确定要申请此提现吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm'){
            return this.$message.info('已取消删除！')
        }
        try {
            const loading = this.$loading({
                lock: true,
                text: '正在提现中，请勿关闭页面！',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const { data: res} = await this.$http.post('checktixian/',{ id:id,admin_token:this.admin_token});
            
            if(res.status === 0){
             this.$message({message: '提现审核成功！',type: 'success'}) 
             this.TiXian(res.username)
             loading.close();
          }
        } catch (error) {
            console.error("请求出错：", error);
        }
        
      },
    async TiXian(username){
          this.txDialogVisible = true
          const { data: res} = await this.$http.post('tixianlist/',{ username:username })
          this.txlist0 = res.arr0
          this.txlist1 = res.arr1
       },
    async YuYue(coachid){
        console.log(coachid);
          this.yyDialogVisible = true
          const { data: res} = await this.$http.post('yuyuelist/',{ coachid:coachid })
          if(res.status == 0){
            this.yylist = res.data
          }
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
</style>