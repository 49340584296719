<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <br>
        <el-tabs v-model="queryInfo.activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="注册会员" name="first">
                <!--搜索与添加区域-->
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-input placeholder="请输入会员手机号/昵称" clearable v-model="queryInfo.query" @clear="getUserList()" style="width: 300px;">
                            <el-button slot="append" icon="el-icon-search" @click="getUserList()"></el-button>
                        </el-input>
                        <!-- <el-select v-model="queryInfo.query4"  placeholder="请选择门店" @change="getUserList()"  style="margin-left:10px" clearable  filterable>
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                        </el-select> -->
                        <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择角色" @change="getUserList()"  style="margin:0 10px">
                                <el-option label="用户" value="0"></el-option>
                                <el-option label="助教" value="1"></el-option>
                                <el-option label="店主" value="2"></el-option>
                        </el-select>
                        <el-select  v-model="queryInfo.query2" clearable  placeholder="是否录脸" @change="getUserList()"  style="margin-right: 10px">
                                <el-option label="已录" value="1"></el-option>
                                <el-option label="未录" value="0"></el-option>
                        </el-select>
                        <el-select  v-model="queryInfo.query3" clearable  placeholder="是否锁定" @change="getUserList()">
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                        </el-select>
                    </el-col>
                    <!-- <el-col :span="2" style="line-height: 40px;">
                        <el-checkbox v-model="queryInfo.tx_user" @change="getUserList">提现用户</el-checkbox>
                    </el-col>
                    <el-col :span="2" style="line-height: 40px;">
                        <el-checkbox v-model="queryInfo.lock_user" @change="getUserList">锁定用户</el-checkbox>
                    </el-col> -->
                    <!-- <el-col :span="14"><el-button type="primary" @click=" addDialogVisible = true">添加用户</el-button></el-col> -->
                </el-row>
                <br>
                <!--搜索与添加区域-->
                <!--用户列表区域-->
                <el-table :data="userlist"  :key="userlist.index" style="width: 100%" stripe size="middle" height="620">
                    <!-- <el-table-column label="ID" type="index"  width="40"></el-table-column> -->
                    <el-table-column label="ID" width="50">
                        <template slot-scope="scope">
                            {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="用户名" prop="username" width="130"></el-table-column>
                    <el-table-column label="昵称" prop="nickname" width="200" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="OpenId" prop="openid" width="240" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="注册门店" prop="storename" width="200" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="注册时间" prop="adddate" width="200"></el-table-column>
                    <el-table-column label="角色" prop="role" width="100">
                        <template  slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.role == '0'">用户</el-tag>
                            <el-tag type="danger" v-if="scope.row.role == '1'">助教</el-tag>
                            <el-tag type="warning" v-if="scope.row.role == '2'">店主</el-tag>
                            <el-tag  v-if="scope.row.role == '3'">店长</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否录脸" prop="isface" width="100">
                        <template slot-scope="scope">
                            <el-tag type="info" v-if="scope.row.isface == '0'">否</el-tag>
                            <el-tag type="success" v-else>是</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否锁定" prop="islock" width="100">
                        <template  slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.islock == '0'">否</el-tag>
                            <el-tag type="danger" v-else>是</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <!--作用域插槽-->
                        <template slot-scope="scope"> 
                            <el-tooltip class="item" effect="dark" content="购卡记录" placement="top">
                                <el-button type="warning" icon="el-icon-tickets" size="mini" @click="BuyList(scope.row.username)"></el-button>
                            </el-tooltip>
                    </template>
                    </el-table-column>
                </el-table>
                <!--用户列表区域-->
                <!--分页区域-->
                <br>
                <div align="center">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                    layout="total, sizes, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
                <!--分页区域-->
            </el-tab-pane>
            <el-tab-pane label="外部购卡会员" name="second">
                <!--搜索与添加区域-->
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-input placeholder="请输入会员手机号/昵称" clearable v-model="queryInfo.query" @clear="getUserList()" style="width: 300px;">
                            <el-button slot="append" icon="el-icon-search" @click="getUserList()"></el-button>
                        </el-input>
                        <!-- <el-select v-model="queryInfo.query4"  placeholder="请选择门店" @change="getUserList()"  style="margin-left:10px" clearable  filterable>
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                        </el-select> -->
                        <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择角色" @change="getUserList()"  style="margin:0 10px">
                                <el-option label="用户" value="0"></el-option>
                                <el-option label="助教" value="1"></el-option>
                                <el-option label="店主" value="2"></el-option>
                        </el-select>
                        <el-select  v-model="queryInfo.query2" clearable  placeholder="是否录脸" @change="getUserList()"  style="margin-right: 10px">
                                <el-option label="已录" value="1"></el-option>
                                <el-option label="未录" value="0"></el-option>
                        </el-select>
                        <el-select  v-model="queryInfo.query3" clearable  placeholder="是否锁定" @change="getUserList()">
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                        </el-select>
                    </el-col>
      
                </el-row>
                <br>
                <!--搜索与添加区域-->
                <!--用户列表区域-->
                <el-table :data="userlist"  :key="userlist.index" style="width: 100%" stripe size="middle" height="620">
                    <!-- <el-table-column label="ID" type="index"  width="40"></el-table-column> -->
                    <el-table-column label="ID" width="50">
                        <template slot-scope="scope">
                            {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="用户名" prop="username" width="130"></el-table-column>
                    <el-table-column label="昵称" prop="nickname" width="200" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="OpenId" prop="openid" width="240" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="注册门店" prop="storename" width="200" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="注册时间" prop="adddate" width="200"></el-table-column>
                    <el-table-column label="角色" prop="role" width="100">
                        <template  slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.role == '0'">用户</el-tag>
                            <el-tag type="danger" v-if="scope.row.role == '1'">助教</el-tag>
                            <el-tag type="warning" v-if="scope.row.role == '2'">店主</el-tag>
                            <el-tag  v-if="scope.row.role == '3'">店长</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否录脸" prop="isface" width="100">
                        <template slot-scope="scope">
                            <el-tag type="info" v-if="scope.row.isface == '0'">否</el-tag>
                            <el-tag type="success" v-else>是</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否锁定" prop="islock" width="100">
                        <template  slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.islock == '0'">否</el-tag>
                            <el-tag type="danger" v-else>是</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <!--作用域插槽-->
                        <template slot-scope="scope"> 
                            <el-tooltip class="item" effect="dark" content="购卡记录" placement="top">
                                <el-button type="warning" icon="el-icon-tickets" size="mini" @click="BuyList(scope.row.username)"></el-button>
                            </el-tooltip>
                            <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelUser(scope.row.username)"></el-button> -->
                            <!-- <el-tooltip class="item" effect="dark" content="提现记录" placement="top">
                                <el-button type="warning" icon="el-icon-sort" size="mini" @click="TiXian(scope.row.username)"></el-button>
                            </el-tooltip> -->
                    </template>
                    </el-table-column>
                </el-table>
                <!--用户列表区域-->
                <!--分页区域-->
                <br>
                <div align="center">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                    layout="total, sizes, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
                <!--分页区域-->
            </el-tab-pane>
        </el-tabs>
            
        <!--购卡记录对话框-->
        <el-dialog title="购卡记录" :visible.sync="buyDialogVisible" width="90%" >
            <el-card>
                <el-table :data="buylist"  :key="buylist.id" style="width: 100%" stripe size="small" height="500">
                    <el-table-column label="ID" type="index" width="50"></el-table-column>
                    <el-table-column label="单号" prop="orderno" width="150"></el-table-column>
                    <el-table-column label="用户" prop="username" width="120"></el-table-column>
                    <el-table-column label="店名" prop="storename" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="卡名" prop="cardname" width="230" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div style="display: flex;">
                                <div style="padding-right: 5px;line-height: 30px;">
                                    {{ scope.row.cardname }}
                                </div>
                                <div v-if="scope.row.cardclass == '体验卡'">
                                    <el-tag type="danger" v-if="scope.row.istuikuan == '1'">退款</el-tag>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="价格" prop="amount" width="80"></el-table-column>
                    <el-table-column label="来源" prop="cardfrom" width="220">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.cardfrom == '0'">平台</el-tag>
                            <el-tag type="danger" v-if="scope.row.cardfrom == '1'">抖音</el-tag>
                            <el-tag type="warning" v-if="scope.row.cardfrom == '2'">美团</el-tag>
                            <span v-if="scope.row.cardfrom == '1'" style="padding-left: 10px;">劵码：{{ scope.row.extra }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="到期时间" prop="endtime" width="150"></el-table-column>
                    
                    <el-table-column label="收款商户" prop="mchid" width="100"></el-table-column>
                    <el-table-column label="状态" prop="status"  width="100">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                            <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买日期" prop="adddate" ></el-table-column>
                </el-table>
            </el-card>
        </el-dialog>
        <!--购卡记录对话框-->
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
       //验证手机的规则
		var checkMobile = (rule,value,cb) => {
			const regMobile = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
			if(regMobile.test(value)){
				return cb()
			}
			cb(new Error('请输入合法的手机号！'))
		}
        return{
            
            queryInfo: {
                activeName: 'first',
                query: '',
                query1:'',
                query2:'',
                query3:'',
                query4:window.sessionStorage.getItem('joinstoreid_token'),//门店ID
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            userlist: [],
            storelist:[],
            buylist:[],
            buyDialogVisible:false,
        }
        
    },
    created() {
         this.getUserList()
    },
    mounted(){
    },
    methods:{
        async getUserList() {
           const { data: res} = await this.$http.get('storeuserlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.userlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.userlist[index].id= res.data.list[index].pk;
            }
            // console.log(this.userlist)
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getUserList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getUserList()
       },
       async BuyList(username){
          this.buyDialogVisible = true
          const { data: res} = await this.$http.post('storebuylist/',{ username:username,storeid:this.queryInfo.query4 })
          if(res.status == 0){
            this.buylist = res.data
            // console.log(res.data)
          }
       },
       handleClick(tab, event) {
            this.queryInfo.pagenum = 1
            this.getUserList()
       }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>