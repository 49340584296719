<template>
    <div>
        <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>财务统计</el-breadcrumb-item>
            <el-breadcrumb-item>财务统计数据</el-breadcrumb-item>
        </el-breadcrumb>
        <br>
        <!--面包屑导航区域-->
        <el-row :gutter="20">
            <el-col :span="6">
                <el-select v-model="queryInfo.query"  placeholder="请选择门店" @change="getData" class="seltect" style="width: 350px;" clearable  filterable>
                    <el-option v-for="item in stores" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                </el-select>
            </el-col>
            <el-col :span="6">
                    <el-date-picker
                    v-model="customdate"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions" @change="CustomeDate">
                    </el-date-picker>
            </el-col>
        </el-row>
        <div style="height: 20px;"></div>
        <el-row :gutter="20">
            <el-col :span="18">
                <el-card>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#36c665,#33f489);border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">今天</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{today.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            抖音验劵：{{today.douyin_income}}元
                                        </div>
                                        <div>
                                            美团验劵：{{today.meituan_income}}元
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            平台收入：{{today.xiaochengxu_income}}元
                                        </div>
                                        <div>
                                            平台退款：{{today.xiaochengxu_refund}}元
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#3271f4,#4ac5ff);border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">昨天</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{yestoday.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                            <div>
                                
                                抖音验劵:{{yestoday.douyin_income}}元
                            </div>
                            <div>
                                
                                美团验劵：{{yestoday.meituan_income}}元
                            </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            平台收入:{{yestoday.xiaochengxu_income}}元
                                        </div>
                                        <div>
                                            
                                            平台退款:{{yestoday.xiaochengxu_refund}}元
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#f89156,#f3443f); border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">近七天</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{last_7_days.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            抖音验劵:{{last_7_days.douyin_income}}元
                                        </div>
                                        <div>
                                            
                                            美团验劵：{{last_7_days.meituan_income}}元
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            平台收入:{{last_7_days.xiaochengxu_income}}元
                                        </div>
                                        <div>
                                            
                                            平台退款:{{last_7_days.xiaochengxu_refund}}元
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <br>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#14c6e0,#00b5d1);border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">上月</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{last_month.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                    <div>
                                        
                                        抖音验劵:{{last_month.douyin_income}}元
                                    </div>
                                    <div>
                                        
                                        美团验劵：{{last_month.meituan_income}}元
                                    </div>
                                </div>
                                <div style="display: flex;justify-content: space-between;padding: 10px;">
                                    <div>
                                        
                                        平台收入:{{last_month.xiaochengxu_income}}元
                                    </div>
                                    <div>
                                        
                                        平台退款:{{last_month.xiaochengxu_refund}}元
                                    </div>
                                </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#fe8b0e,#fe9a22);border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">近三个月</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{last_3_months.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            抖音验劵:{{last_3_months.douyin_income}}元
                                        </div>
                                        <div>
                                            
                                            美团验劵：{{last_3_months.meituan_income}}元
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            平台收入:{{last_3_months.xiaochengxu_income}}元
                                        </div>
                                        <div>
                                            
                                            平台退款:{{last_3_months.xiaochengxu_refund}}元
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card style="background-image: linear-gradient(to right,#5bb1d7,#068eca); border-radius: 10px;color: #eee;">
                                <div style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;">  
                                        <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                        <div style="padding-left: 20px;">
                                            <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">近半年</div>
                                            <div style="font-size: 25px;font-weight: bold;">{{last_6_months.total_income}}元</div>
                                        </div>
                                    </div>
                                    <div >
                                        <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                                    </div>
                                </div>
                                <div style="height: 50px;"></div>
                                <div style="color: #eee;">
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            抖音验劵:{{last_6_months.douyin_income}}元
                                        </div>
                                        <div>
                                            
                                            美团验劵：{{last_6_months.meituan_income}}元
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 10px;">
                                        <div>
                                            
                                            平台收入:{{last_6_months.xiaochengxu_income}}元
                                        </div>
                                        <div>
                                            
                                            平台退款:{{last_6_months.xiaochengxu_refund}}元
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card style="height: 580px;">
                    <el-card v-if="customdate !== null && customdate.length > 0" style="background-image: linear-gradient(to right,#5bb1d7,#068eca); border-radius: 10px;color: #eee;">
                        <div style="display: flex;justify-content: space-between;">
                            <div style="display: flex;">  
                                <img src="../assets/image/yuan.png" style="width: 20px;height:20px;vertical-align: middle;margin-top: 10px;"></img>
                                <div style="padding-left: 20px;">
                                    <div style="font-size: 25px;font-weight: bold;padding-bottom: 20px;">{{customdate[0]}} 至 {{ customdate[1] }}</div>
                                    <div style="font-size: 25px;font-weight: bold;">{{custom_date.total_income}}元</div>
                                </div>
                            </div>
                            <div >
                                <img src="../assets/image/zong.png" style="height: 80px;vertical-align: middle;padding-right: 10px;" />
                            </div>
                        </div>
                        <div style="height: 50px;"></div>
                        <div style="color: #eee;">
                            <div style="display: flex;justify-content: space-between;padding: 10px;">
                                <div>
                                    
                                    抖音验劵:{{custom_date.douyin_income}}元
                                </div>
                                <div>
                                    
                                    美团验劵：{{custom_date.meituan_income}}元
                                </div>
                            </div>
                            <div style="display: flex;justify-content: space-between;padding: 10px;">
                                <div>
                                    
                                    平台收入:{{custom_date.xiaochengxu_income}}元
                                </div>
                                <div>
                                    
                                    平台退款:{{custom_date.xiaochengxu_refund}}元
                                </div>
                            </div>
                        </div>
                    </el-card>
                    <div v-else style="text-align: center;padding-top: 100px;">
                        <img src="../assets/image/null.png" style="height: 200px;" />
                        <div style="text-align: center;color:#ccc">暂无信息</div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div style="height: 20px;"></div>
        <el-card>
            <div slot="header" class="clearfix">
                <span style="font-size: 20px;font-weight: bold;">{{month1}}月度销量统计</span>
                <el-date-picker style="float: right" v-model="month1" value-format="yyyy-MM" type="month" placeholder="选择月" @change="ChangeMonth()" :clearable="false" :editable="false"></el-date-picker>
            </div>
            <div ref="MonthContainer" style="width: 100%; height: 400px;"></div>
        </el-card>
        <div style="height: 20px;"></div>
        <el-card>
            <div slot="header" class="clearfix">
                <span style="font-size: 20px;font-weight: bold;">{{year1}}年度销量统计</span>
                <el-date-picker style="float: right" v-model="year1" value-format="yyyy" type="year" placeholder="选择年" @change="ChangeYear()" :clearable="false" :editable="false"></el-date-picker>
            </div>
            <div ref="YearContainer" style="width: 100%; height: 400px;"></div>
        </el-card>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return{
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            customdate:'',
            stores:[],
            queryInfo: {
                query: '',
                startdate: '',
                enddate: ''
            },
            today:{},
            yestoday:{},
            last_7_days:{},
            last_month:{},
            last_3_months:{},
            last_6_months:{},
            custom_date:{},
            month1:this.getCurrentYearMonth(),
            days:[],
            datas:[],
            year1:this.getCurrentYear(),
            months:[],
            ydatas:[],
        }
    },
    async created() {
         this.getData()
         await this.getEchartMData(); // 等待数据加载完毕
         await this.getEchartYData(); // 等待数据加载完毕
    },
    mounted(){
        if (this.datas.length) {
            this.showEchart(); // 数据加载完后再渲染图表
        }
    },
    watch: {
        datas(newVal) {
            if (newVal.length) {
                this.showEchart(); // 数据更新时重新渲染图表
            }
        },
        ydatas(newVal) {
            if (newVal.length) {
                this.showEchart(); // 数据更新时重新渲染图表
            }
        }
    },
    methods:{
        async getData() {
           const { data: res} = await this.$http.post('tongji/',this.queryInfo)
           if(res.status == 0){
                this.stores = res.data.stores
                this.today = res.data.periods.today
                this.yestoday = res.data.periods.yesterday
                this.last_7_days = res.data.periods.last_7_days
                this.last_month = res.data.periods.last_month
                this.last_3_months = res.data.periods.last_3_months
                this.last_6_months = res.data.periods.last_6_months
                this.custom_date = res.data.custom_income
           }
           this.getEchartMData()
           this.getEchartYData()
        },
        CustomeDate(){
            console.log(this.customdate);
            if(this.customdate == null){
                this.queryInfo.startdate = ""
                this.queryInfo.enddate = ""
            }else{
                this.queryInfo.startdate = this.customdate[0]
                this.queryInfo.enddate = this.customdate[1]
            }
            this.getData()
        },
        async getEchartMData(){
            const { data: res} = await this.$http.get('echartsmonthdata/',{params:{rq:this.month1,storeid:this.queryInfo.query}})
            if(res.status == 0){
                this.days = res.days
                this.datas = res.data;
                console.log(this.datas)
            }
        },
        async getEchartYData(){
            const { data: res} = await this.$http.get('echartsyeardata/',{params:{rq:this.year1,storeid:this.queryInfo.query}})
            if(res.status == 0){
                this.months = res.months
                this.ydatas = res.data;
            }
        },
        // 渲染图表
        showEchart() {
            // 初始化ECharts实例
            const myChartMonth = echarts.init(this.$refs.MonthContainer);
            const myChartYear = echarts.init(this.$refs.YearContainer);
            // 使用刚指定的配置项和数据显示图表
            myChartMonth.setOption({
                title: {
                    text: '单位(元)',
                    textStyle: {
                        color: "rgb(85, 85, 85)",
                        fontSize: 18,
                        fontStyle: "normal",
                        fontWeight: "normal"
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['总金额', '平台', '抖音', '美团', '退款'],
                    selectedMode: true,
                },
                toolbox: {
                    show: true,
                        feature: {
                            dataView: {
                                show: false,
                                readOnly: true
                            },
                            magicType: {
                                show: true,
                                type: ["line", "bar", "stack", "tiled"]
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            },
                            mark: {
                                show: false
                            }
                        }
                },
                calculable: false,
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.days
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: this.datas.map(item => ({
                    name: item.name,
                    type: 'line',
                    // stack: 'Total',
                    smooth: true,
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data: item.data
                }))
            });
            myChartYear.setOption({
                title: {
                    text: '单位(元)',
                    textStyle: {
                        color: "rgb(85, 85, 85)",
                        fontSize: 18,
                        fontStyle: "normal",
                        fontWeight: "normal"
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['总金额', '平台', '抖音', '美团', '退款'],
                    selectedMode: true,
                },
                toolbox: {
                    show: true,
                        feature: {
                            dataView: {
                                show: false,
                                readOnly: true
                            },
                            magicType: {
                                show: true,
                                type: ["line", "bar", "stack", "tiled"]
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            },
                            mark: {
                                show: false
                            }
                        }
                },
                calculable: false,
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: this.months
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: this.ydatas.map(item => ({
                    name: item.name,
                    type: 'bar',
                    // stack: 'Total',
                    // smooth: true,
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data: item.data
                }))
            });
        },
        ChangeMonth(){
            this.getEchartMData()
        },
        getCurrentYearMonth() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');  // 确保月份是两位数
            return `${year}-${month}`;
        },
        getCurrentYear() {
            const now = new Date();
            const year = now.getFullYear();
            return `${year}`;
        },
        ChangeYear(){
            this.getEchartYData()
        },
    }
}
</script>
<style scoped>
    .el-breadcrumb{
        width: 100%;
    }
</style>