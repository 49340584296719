<template>
    <div>
        <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员卡管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员卡列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入会员卡名称" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="14"><el-button type="primary" @click=" addDialogVisible = true">上架 / 下架会员卡</el-button></el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list"  :key="list.index" style="width: 100%" stripe size="small" height="430">
                <el-table-column label="ID" type="index"  width="40"></el-table-column>
                <el-table-column label="会员卡名称" prop="cardname" width="200"></el-table-column>
                <el-table-column label="会员卡类型" prop="cardclass" width="200"></el-table-column>
                <el-table-column label="价格" prop="price" width="200"></el-table-column>
                <el-table-column label="描述" prop="carddescribe" width="200"></el-table-column>
                <el-table-column label="备注" prop="remark" width="200"></el-table-column>
                <el-table-column label="排序" prop="sortid" width="100"></el-table-column>
                <!-- <el-table-column label="排序" prop="sortid" width="100"></el-table-column> -->
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="warning" icon="el-icon-arrow-up" size="mini" @click="SortInfo(scope.row.id,'up')" circle></el-button>
                        <el-button type="warning" icon="el-icon-arrow-down" size="mini" @click="SortInfo(scope.row.id,'down')" circle></el-button>
                        <el-tooltip class="item" effect="dark" content="下架" placement="top">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>

                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加用户对话框-->
            <el-dialog title="上架/下架会员卡" :visible.sync="addDialogVisible" width="50%">
                
                <div style="display: flex; justify-content: center;">
                    <el-transfer v-model="mycards" :data="cards" :titles="['会员卡种类', '我的会员卡']"></el-transfer>
                </div>
                
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="AddInfo">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加用户对话框-->
        </el-card>
    </div>
</template>
<script>
    export default {
        data() {
            return{
                queryInfo: {
                    storeid:window.sessionStorage.getItem('joinstoreid_token'),
                    query: '',
                    pagenum: 1,
                    pagesize: 10
                },
                total:0,
                list: [],
                addDialogVisible:false,
                editDialogVisible:false,
                storeid:window.sessionStorage.getItem('joinstoreid_token'),

                classlist:[],
                cards: [],
                mycards: []
            }
        },
        created() {
            this.getClass()
            this.getList()
            this.generateData()
        },
        methods:{
            async generateData() { 
                const { data: res } = await this.$http.get('cards/',{params:{storeid:this.storeid}})
                console.log(res);
                this.cards = res.data
                this.mycards = res.mydata
            },
            async getClass(){
                const { data: res } = await this.$http.get('cardclass/')
                this.classlist = res.data
                // console.log(this.classlist);
            },
            async getList() {
                const { data: res} = await this.$http.get('storecardlist/',{ params: this.queryInfo })
                if(res.status == 1){
                        this.total = res.data.totalCount;
                        this.list = res.data.list.map(v => {
                        //在返回的数据里增加一条数据
                        // console.log(v.fields)
                        return v.fields
                        })
                    }
                    for (let index = 0; index < res.data.list.length; index++) {
                        this.list[index].id= res.data.list[index].pk;
                    }
                    // console.log(this.userlist)
            },
            handleSizeChange(newSize){
            // console.log(newSize)
            this.queryInfo.pagesize = newSize
            this.getList()
        },
        handleCurrentChange(newPage){
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getList()
        },
        async AddInfo(){

                const { data:res } = await this.$http.post('storecardadd/',{storeid:this.storeid,mycards:this.mycards})
                if(res.status===0)
                {
                    this.$message.success('上架成功！')
                    this.addDialogVisible = false
                    this.mycards = []
                    this.getList()
                    this.generateData()
                }
        },
        async SortInfo(id,sign){
            const { data: res } = await this.$http.get('storecardsort/'+ id,{ params: {sign:sign} })
            if(res.status == 0){
                this.$message.success('设置成功！')
                this.getList()
            }
            
        },
        async DelInfo(id){
            const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('storecarddel/'+ id)
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getList()
        },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>