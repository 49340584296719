<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-input placeholder="请输入会员手机号/昵称" clearable v-model="queryInfo.query" @clear="getUserList()" style="width: 300px;">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList()"></el-button>
                    </el-input>
                    <el-select v-model="queryInfo.query4"  placeholder="请选择门店" @change="getUserList()"  style="margin-left:10px" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择角色" @change="getUserList()"  style="margin:0 10px">
                            <el-option label="用户" value="0"></el-option>
                            <el-option label="助教" value="1"></el-option>
                            <el-option label="店主" value="2"></el-option>
                            <el-option label="保洁" value="3"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query2" clearable  placeholder="是否录脸" @change="getUserList()"  style="margin-right: 10px">
                            <el-option label="已录" value="1"></el-option>
                            <el-option label="未录" value="0"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query3" clearable  placeholder="是否锁定" @change="getUserList()"  style="margin-right: 60px">
                            <el-option label="是" value="1"></el-option>
                            <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-col>
                <!-- <el-col :span="2" style="line-height: 40px;">
                    <el-checkbox v-model="queryInfo.tx_user" @change="getUserList">提现用户</el-checkbox>
                </el-col>
                <el-col :span="2" style="line-height: 40px;">
                    <el-checkbox v-model="queryInfo.lock_user" @change="getUserList">锁定用户</el-checkbox>
                </el-col> -->
                <!-- <el-col :span="2"></el-col> -->
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="userlist"  :key="userlist.index" style="width: 100%" stripe size="middle" height="700">
                <!-- <el-table-column label="ID" type="index"  width="40"></el-table-column> -->
                <el-table-column label="ID" width="50">
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="用户名" prop="username" width="120"></el-table-column>
                <el-table-column label="昵称" prop="nickname" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="OpenId" prop="openid" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="性别" prop="sex" width="80"></el-table-column>
                <el-table-column label="年龄" prop="age" width="80"></el-table-column>
                
                <!-- <el-table-column label="禁止购卡" prop="iscard" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.iscard == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="禁止门禁" prop="isdoor" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.isdoor == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="禁止录脸" prop="isface" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.isface == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column label="是否店主" prop="ismanager" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.ismanager == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column> -->
                
                <el-table-column label="注册门店" prop="storename" width="200" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column label="注册时间" prop="adddate" width="180"></el-table-column>
                <el-table-column label="角色" prop="role" width="80">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.role == '0'">用户</el-tag>
                        <el-tag type="danger" v-if="scope.row.role == '1'">助教</el-tag>
                        <el-tag type="warning" v-if="scope.row.role == '2'">店主</el-tag>
                        <el-tag  v-if="scope.row.role == '3'">保洁</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否录脸" prop="isface" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.isface == '0'">否</el-tag>
                        <el-tag type="success" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否锁定" prop="islock" width="80">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.islock == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="EditUser(scope.row.username)"></el-button>
                        <!-- <el-tooltip class="item" effect="dark" content="购卡记录" placement="top"> -->
                            <el-button type="warning" size="mini" @click="BuyList(scope.row.username)">购卡记录</el-button>
                        <!-- </el-tooltip> -->
                        <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelUser(scope.row.username)"></el-button> -->
                        <!-- <el-tooltip class="item" effect="dark" content="提现记录" placement="top">
                            <el-button type="warning" icon="el-icon-sort" size="mini" @click="TiXian(scope.row.username)"></el-button>
                        </el-tooltip> -->
                </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加用户对话框-->
            <el-dialog title="添加会员" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="addForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="是否是店主" prop="ismanager">
                        <el-switch active-value="1" inactive-value="0" v-model="addForm.ismanager"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否是保洁" prop="isbaojie">
                        <el-switch active-value="1" inactive-value="0" v-model="addForm.isbaojie"></el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addUser">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加用户对话框-->
            <!--编辑用户对话框-->
            <el-dialog title="编辑会员" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="editForm.username" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="是否是店主" prop="role">
                        <el-switch active-value="2" inactive-value="0" v-model="editForm.role"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否是保洁" prop="role1">
                        <el-switch active-value="3" inactive-value="0" v-model="editForm.role1"></el-switch>
                    </el-form-item>
                    <!-- <el-form-item label="关联门店" prop="storeid" v-if="editForm.role == '2' || editForm.ismanager == 1">
                        <el-select multiple v-model="storeid"  placeholder="请选择类型" style="width:300px">
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="是否锁定" prop="islock">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.islock"></el-switch>
                    </el-form-item>
                    <!-- <el-form-item label="禁止在线购卡" prop="iscard">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.iscard"></el-switch>
                    </el-form-item>
                    <el-form-item label="禁止开启门禁" prop="isdoor">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.isdoor"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否录入人脸" prop="isface">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.isface"></el-switch>
                    </el-form-item> -->
                    
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditUserSave(editForm.username,editForm.id)">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑用户对话框-->
            <!--提现记录对话框-->
            <!-- <el-dialog title="提现记录" :visible.sync="txDialogVisible" width="60%" >
                <el-card>
                    <el-table :data="txlist0"  :key="txlist0.id" style="width: 100%"  size="small" height="50" :show-header="false" >
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="reg_time"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="warning">待审</el-tag>
                             </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.sign" @change="Checked(scope.row.id)"></el-switch>  <span style="color:#ff0000">审核</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <br>
                <el-card>
                    <el-table :data="txlist1"  :key="txlist1.id" style="width: 100%" stripe size="small" height="300">
                        <el-table-column label="ID" type="index"></el-table-column>
                        <el-table-column label="提现单号" prop="orderno"></el-table-column>
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="adddate"></el-table-column>
                        <el-table-column label="审核时间" prop="checktime"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="success">已审</el-tag>
                             </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-dialog> -->
            <!--提现记录对话框-->
            <!--购卡记录对话框-->
            <el-dialog title="购卡记录" :visible.sync="buyDialogVisible" width="85%" >
                <el-card>
                    <el-table :data="buylist"  :key="buylist.id" style="width: 100%" stripe size="small" height="500">
                        <el-table-column label="ID" type="index" width="50"></el-table-column>
                        <el-table-column label="单号" prop="orderno" width="150"></el-table-column>
                        <el-table-column label="用户" prop="username" width="120"></el-table-column>
                        <el-table-column label="卡名" prop="cardname" width="260" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <div style="display: flex;">
                                    <div style="padding-right: 5px;line-height: 30px;">
                                        {{ scope.row.cardname }}
                                    </div>
                                    <div v-if="scope.row.cardclass == '体验卡'">
                                        <el-tag type="danger" v-if="scope.row.istuikuan == '1'">退款</el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="价格" prop="amount" width="80"></el-table-column>
                        <el-table-column label="来源" prop="cardfrom" width="220">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.cardfrom == '0'">平台</el-tag>
                                <el-tag type="danger" v-if="scope.row.cardfrom == '1'">抖音</el-tag>
                                <el-tag type="warning" v-if="scope.row.cardfrom == '2'">美团</el-tag>
                                <el-tag type="primary" v-if="scope.row.cardfrom == '3'">赠送</el-tag>
                                <el-tag type="info" v-if="scope.row.cardfrom == '4'">分享</el-tag>
                                <span v-if="scope.row.cardfrom == '1'" style="padding-left: 10px;">劵码：{{ scope.row.extra }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="到期时间" prop="endtime" width="150"></el-table-column>
                        
                        <el-table-column label="收款商户" prop="mchid" width="100"></el-table-column>
                        <el-table-column label="状态" prop="status"  width="100">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                                <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="购买日期" prop="adddate" ></el-table-column>

                        <!-- <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="adddate"></el-table-column>
                        <el-table-column label="审核时间" prop="checktime"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="success">已审</el-tag>
                             </template>
                        </el-table-column> -->
                    </el-table>
                </el-card>
            </el-dialog>
            <!--购卡记录对话框-->
            
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
       //验证手机的规则
		var checkMobile = (rule,value,cb) => {
			const regMobile = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
			if(regMobile.test(value)){
				return cb()
			}
			cb(new Error('请输入合法的手机号！'))
		}
        return{
            
            queryInfo: {
                query: '',
                query1:'',
                query2:'',
                query3:'',
                query4:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            userlist: [],
            storelist:[],
            txlist0:[],
            txlist1:[],
            buylist:[],
            addDialogVisible:false,
            editDialogVisible:false,
            txDialogVisible:false,
            buyDialogVisible:false,
            addForm:{
                 username:'',
                 ismanager:0,
                 isbaojie:0
            },
             //编辑表单数据
             editForm:{
                username:'',
                iscard:0,
                isdoor:0,
                isface:0,
                ismanager:0,
                isbaojie:0,
                islock:0,
            },
            
            //添加表单验证规则
            addFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur'}
                ]
            },
            editFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur'}
                ]
            }
        }
        
    },
    created() {
         this.GetStore()
         this.getUserList()
    },
    methods:{
        async getUserList() {
           const { data: res} = await this.$http.get('userlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.userlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.userlist[index].id= res.data.list[index].pk;
            }
            // console.log(this.userlist)
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getUserList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getUserList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       
       async addUser(){
              this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('useradd/',this.addForm)
                if(res.status===0)
                {
                    this.$message.success('添加用户成功！')
                    this.addDialogVisible = false
                    this.getUserList()
                }
                if(res.status == 1){
                    this.$message.error('此用户已存在！')
                }
              })
       },
       async EditUser(username){
            const { data: res } = await this.$http.get('userinfo/'+username)
            this.editForm = res
            if(res.role == '2'){
                this.editForm.ismanager = '1'
            }
            if(res.role == '3'){
                this.editForm.role1 = '3'
            }
            
            this.editDialogVisible = true
            this.GetStore()
       },
       async GetStore(){
            const { data: res } = await this.$http.get('store/')
            if(res.status == 0){
                this.storelist = res.data
            }
       },
       EditUserSave(username,userid){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('useredit/' + username,this.editForm)
                if (res.status === 0) {
                    this.$message.success('用户修改成功')
                    this.editDialogVisible = false
                    this.getUserList()
                    console.log(this.editForm.islock);
                    var usertype = 0
                    if(this.editForm.islock == 1){
                        usertype = 10
                    }
                }
            })
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
    //    async DelUser(username){
    //             const confirmResult = await this.$confirm('是否要删除此用户吗?', '提示', {
    //                 confirmButtonText: '确定',
    //                 cancelButtonText: '取消',
    //                 type: 'warning'
    //                 }).catch(err => err)

    //                 if (confirmResult !== 'confirm'){
    //                     return this.$message.info('已取消删除！')
    //                 }
    //                 const { data: res } = await this.$http.get('userdel/'+ username)
                
    //                 if (res.status === 0) { 
    //                     this.$message({message: '删除成功！',type: 'success'})
    //                 }
    //                 this.getUserList()
    //    },
    //    async TiXian(username){
    //       this.txDialogVisible = true
    //       const { data: res} = await this.$http.post('tixianlist/',{ username:username })
    //       this.txlist0 = res.arr0
    //       this.txlist1 = res.arr1
    //    },
    //    async Checked(id){
    //         const confirmResult = await this.$confirm('确定要申请此提现吗?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //         }).catch(err => err)

    //         if (confirmResult !== 'confirm'){
    //             return this.$message.info('已取消删除！')
    //         }
    //         try {
    //             const { data: res} = await axios.post('https://jianshenapi.tieshouyundong.com/tixian',{ id:id});
    //             console.log("响应数据：", res);
    //             if(res.status === 0){
    //             this.$message({message: '提现审核成功！',type: 'success'}) 
    //             this.TiXian(res.username)
    //             this.getUserList()
    //         }
    //         } catch (error) {
    //             console.error("请求出错：", error);
    //         }
        
    //   },
      async BuyList(username){
          this.buyDialogVisible = true
          const { data: res} = await this.$http.post('buylist/',{ username:username })
          if(res.status == 0){
            this.buylist = res.data
            // console.log(res.data)
          }
     }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>