<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--商品列表区域-->
            <el-table :data="list" :key="list.index" style="width: 100%" stripe size="middle" height="420">
                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="内容" prop="rule"  width="1000"></el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="EditInfo(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--编辑用户对话框-->
            <el-dialog title="编辑计算规则" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="rule">
                        <el-input v-model="editForm.rule" type="textarea" :rows="3" maxlength="100" show-word-limit style="width:600px"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditSave(editForm.id)">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑用户对话框-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            editDialogVisible:false,
            editForm:{}
        }
        
    },
    created() {
         this.getList()
    },
    
    methods:{
        async getList() {
            const { data:res } = await this.$http.get('systemlist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async EditInfo(id){
            const { data: res } = await this.$http.get('systeminfo/'+id)
            this.editForm = res
            this.editForm.admin_token =window.sessionStorage.getItem('admin_token')
            this.editDialogVisible = true
       },
       EditSave(id){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('systemedit/' + id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('信息修改成功')
                    this.editDialogVisible = false
                    this.getList()
                }
            })
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>