<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>加盟商管理</el-breadcrumb-item>
            <el-breadcrumb-item>收支明细</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="18">
                    <el-select v-model="queryInfo.query"  placeholder="请选择门店" @change="getList"  style="width: 350px;" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择交易类型" @change="getList()"  style="margin:0 10px">
                            <el-option label="平台" value="1"></el-option>
                            <el-option label="扣点" value="2"></el-option>
                            <el-option label="退款" value="3"></el-option>
                            <el-option label="抖音" value="4"></el-option>
                            <el-option label="美团" value="5"></el-option>
                    </el-select>
                        <el-date-picker
                        v-model="customdate"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions" @change="CustomeDate">
                        </el-date-picker>
                </el-col>
                <el-col :span="6" style="text-align: right">
                    <download-excel :data = "exclelist" :fields = "excel_fields" name = "收支明细">
                        <el-button type="primary" icon="el-icon-upload2" @click="CheckDate">导出Excle</el-button>
                    </download-excel>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle">
                <el-table-column label="ID" type="index" ></el-table-column>
                <el-table-column label="门店名称" prop="storename" width="200"  show-overflow-tooltip></el-table-column>
                <!-- <el-table-column label="加盟商" prop="name" width="150" ></el-table-column> -->
                <el-table-column label="内容" prop="content" width="300" show-overflow-tooltip></el-table-column>
                <el-table-column label="收入/支出金额" prop="sign">
                    <template slot-scope="scope">
                        <span :style="{ color: scope.row.sign === '+' ? 'green' : 'red' }">
                            {{ scope.row.sign }} {{ scope.row.amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="交易类型" prop="type">
                    <template slot-scope="scope">
                        <span>
                            <el-tag v-if="scope.row.type=='1'"  type="success">平台</el-tag>
                            <el-tag v-else-if="scope.row.type=='2'" type="danger">扣点</el-tag>
                            <el-tag v-else-if="scope.row.type=='3'" type="info">退款</el-tag>
                            <el-tag v-else-if="scope.row.type=='4'" type="warning">抖音</el-tag>
                            <el-tag v-else-if="scope.row.type=='5'" type="primary">美团</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="日期" prop="adddate"></el-table-column>
            </el-table>
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            customdate:'',
            queryInfo: {
                query: '',
                query1:'',
                startdate:'',
                enddate:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            storelist:[],
            exclelist:[],
            excel_fields: {  //导出Excel表格的表头设置
                '门店名称': 'storename',
                // '加盟商':'name',
                '内容':'content',
                '收入/支出':'sign',
                '收支金额': 'amount',
                '交易类型': 'type',
                '日期': 'adddate'
            }
        }
        
    },
    created() {
         this.getList()
         this.getStoreList()
    },
    
    methods:{
        async getStoreList(){
            const { data: res } = await this.$http.get('store/')
            this.storelist = res.data
        },
        async getList() {
            const { data:res } = await this.$http.get('inexplist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        async getExcleData(){
            const { data:res } = await this.$http.get('inexpexllist/',{ params: this.queryInfo })
            if(res.status == 0){
                this.exclelist = res.data
                console.log(this.exclelist);
            }
        },
        CheckDate(){
            if (this.customdate.length == 0){
                this.$message.error('请先选择日期,最多支持导出一个月的流水！')
            }else{
                
                const startDate = new Date(this.customdate[0]);
                const endDate = new Date(this.customdate[1]);
            
                // 计算一个月后的日期
                const oneMonthLater = new Date(startDate);
                oneMonthLater.setMonth(startDate.getMonth() + 1);
                console.log(startDate);
                console.log(endDate);
                if (endDate > oneMonthLater) {
                    this.$message.error('只能导出一个月的流水！');
                    this.exclelist = []
                }
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       CustomeDate(){
            console.log(this.customdate);
            if(this.customdate == null){
                this.queryInfo.startdate = ""
                this.queryInfo.enddate = ""
            }else{
                this.queryInfo.startdate = this.customdate[0]
                this.queryInfo.enddate = this.customdate[1]
                this.getExcleData()
            }
            this.getList()
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>