<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/goodslist' }">门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>标注门店</el-breadcrumb-item>
        </el-breadcrumb>
        <!--卡片视图区-->
        <el-card>
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"  label-width="100px" style="width:90%;margin:10px auto">
                    <el-form-item label="门店名称" prop="storename">
                            <el-input v-model="editForm.storename"></el-input>
                    </el-form-item>    
                    <el-form-item label="所属城市" prop="city">
                        <el-cascader v-model="editForm.city" :options="citylist"></el-cascader>
                    </el-form-item>
                    <el-form-item label="门店地址" prop="address">
                        <el-input v-model="editForm.address" style="width:600px"  clearable @blur="addressChange(15)"></el-input>
                    </el-form-item>
                    <el-form-item label="门店定位">
                        <tx-map ref="map" @setAddress="setAddress" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="EditStoreSave">确 定</el-button>
                        <el-button >取 消</el-button>
                    </el-form-item>
                </el-form>
        </el-card>
    </div>
</template>
<script>
import TxMap from "../../components/common/TXMap"
import { cities } from '@/assets/js/city.js'
export default {
    components: { TxMap },
    data(){
        return {
            imgURL:this.GLOBAL.imgURL,
            uploadurl: this.GLOBAL.imgURL+'upload/',
            limitNum: 1,
            fileList:[],
            cities:cities,
            citylist:[],
            statuslist:[],
            id:'',
            //编辑表单数据
            editForm:{
                storename:'',
                city:'',
                address:'东营市',
                localtion:'',
                prov_name: "",
                city_name: "",
                district_name: "",
                latitude:'',
                longitude:'',
                storepic:'',
                roomnum:'',
                storearea:'',
                devicetype:'',
                wechat:'',
                telephone:'',
                wifi:'',
                jianjie:'',
                isopen:1,
                sn:'',
                outsn:'',
                sn1:'',
                outsn1:'',
                camera1:'',
                camera2:'',
                camera3:'',
                camera4:'',
                fishsn:'',
                fishsn1:'',
                boxsn:'',
                wsdsn:''
            },
            //编辑表单验证规则
            editFormRules:{
                storename:[
                    { required: true, message: '请选输入门店名称', trigger: 'blur' }
                ],
                city:[
                    { required: true, message: '请选择所属城市', trigger: 'blur' }
                ],
                address:[
                    { required: true, message: '请选择门店地址', trigger: 'blur' }
                ],
                localtion:[
                    { required: true, message: '请标注门店位置', trigger: 'blur' }
                ],
            },
        }
    },
    created(){
        this.id = this.$route.params.id
        this.getCityList()
        this.editStore()
        this.getStatusList()
    },
    mounted(){
        
    },
    methods:{
        getCityList(){
            const transformedData = this.cities.map(city => {
                const cityObject = {
                    value: city.name,
                    label: city.name,
                    children: city.districts ? city.districts.map(district => ({
                        value: district,
                        label: district
                    })) : []
                };
                return cityObject;
                
            });
            this.citylist = transformedData
        },
        async getStatusList(){
            const { data: res } = await this.$http.get('status/')
            this.statuslist = res.data
        },
        async editStore(){
            const { data: res } = await this.$http.get('storeinfo/' + this.id )
            this.editForm = res
            const cityarr = []
            cityarr.push(res.city)
            cityarr.push(res.county)
            this.editForm.city = cityarr
            this.fileList = [{name:res.storepic,url:this.imgURL+'static/pics/'+res.storepic}]
            this.addressChange(15)
            this.editForm.admin_token = window.sessionStorage.getItem('admin_token') 
        },
        change(data) {
            this.editForm.goods_info = data;
        },
        editPic(file, fileList){
            this.editForm.storepic = file.picname
        },
        setAddress(newAddress) {
            this.editForm.address =  newAddress.formatted_addresses.recommend; //地址
            this.editForm.latitude = newAddress.location.lat; //纬度
            this.editForm.longitude = newAddress.location.lng; //经度
            this.editForm.prov_name = newAddress.address_component.province; //省
            this.editForm.city_name = newAddress.address_component.city; //市
            this.editForm.district_name = newAddress.address_component.district; //区
            this.editForm = Object.assign({}, this.editForm, {
            address: newAddress.formatted_addresses.recommend,
            });
            // console.log("this.form.store_address", this.form.store_address);
        },
        addressChange(zoom = 15) {
            let map_key = "GX3BZ-2XRK4-JIOUX-XP53Z-P2AV3-VIBZU"; //腾讯地图key
            //省市区
            let prov_name = this.editForm.prov_name || "",
            city_name = this.editForm.city_name || "",
            district_name = this.editForm.district_name || "";
            //如果有省市区的选择，地图搜索就要把省市区组装起来，传入到address参数里
            let store_city = prov_name + city_name + district_name;
    
            // 地址转坐标
            this.$jsonp(
            `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&key=${map_key}&address=${store_city}${this.editForm.address}`
            ).then((res) => {
            // 根据点击地点获取经纬度
            let lat = res.result.location.lat; //纬度
            let lng = res.result.location.lng; //经度
            //在地图上定位地点
            this.$refs["map"].init(
                zoom,
                lat,
                lng,
                this.editForm.address
            );
            });
        },
        async EditStoreSave(){
            // this.editForm.city = this.editForm.city.join(', ')
            console.log(this.editForm);
              this.$refs.editFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('storemark/'+this.id,this.editForm)
                if(res.status===0)
                {
                    this.$message.success('修改门店成功！')
                    this.$router.push({path: '/stores'})
                }
              })
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
        
    }
    .el-breadcrumb{
        width: 100%;
    }
    
</style>