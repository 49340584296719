<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>开店信息</el-breadcrumb-item>
            <el-breadcrumb-item>信息列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入城市名称" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list"  :key="list.index" style="width: 100%" stripe size="middle" height="430">
                <el-table-column label="ID" type="index"  width="40"></el-table-column>
                <el-table-column label="姓名" prop="name" width="120"></el-table-column>
                <el-table-column label="手机" prop="telephone" width="200"></el-table-column>
                <el-table-column label="预算" prop="budget" width="200"></el-table-column>
                <el-table-column label="城市" prop="city" width="200"></el-table-column>
                <el-table-column label="是否有房" prop="isroom" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.isroom == '有'">有</el-tag>
                        <el-tag type="danger" v-else>无</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" width="200"></el-table-column>
                <el-table-column label="填写时间" prop="adddate"></el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>
                </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: []
        }
        
    },
    created() {
         this.getList()
    },
    methods:{
        async getList() {
           const { data: res} = await this.$http.get('openstorelist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async DelInfo(id){
                const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('openstoredel/'+ id)
                
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getList()
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>