import { render, staticRenderFns } from "./JoinWelcome.vue?vue&type=template&id=3ff7b906&scoped=true"
import script from "./JoinWelcome.vue?vue&type=script&lang=js"
export * from "./JoinWelcome.vue?vue&type=script&lang=js"
import style0 from "./JoinWelcome.vue?vue&type=style&index=0&id=3ff7b906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff7b906",
  null
  
)

export default component.exports