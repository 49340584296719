<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/stores' }">门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>添加门店</el-breadcrumb-item>
        </el-breadcrumb>
        <!--卡片视图区-->
        <el-card>
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef"  label-width="100px" style="width:90%;margin:10px auto">
                    <el-form-item label="门店名称" prop="storename">
                        <el-input v-model="addForm.storename"  style="width:600px"></el-input>
                    </el-form-item>
                    <el-form-item label="所属城市" prop="city">
                        <el-cascader :options="citylist" @change="handleChange"></el-cascader>
                    </el-form-item>
                    <el-form-item label="门店照片" prop="goods_pic">
                        <el-upload
                            ref="imgBroadcastUpload"
                            :limit="limitNum"
                            :auto-upload="true"
                            :file-list="fileList"
                            :on-success="addPic"
                            list-type="picture-card"
                            accept="image/jpg"
                            :action="uploadurl">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="门店位置" prop="address">
                        <el-input v-model="addForm.address" style="width:600px" clearable @input="addressChange(15)"></el-input>
                    </el-form-item>
                    
                    <!-- <el-form-item label="经纬度" prop="localtion">
                        <el-input v-model="addForm.localtion" style="width:600px"></el-input>
                    </el-form-item> -->
                    <el-form-item label="门店定位" prop="location">
                        <tx-map ref="map" @setAddress="setAddress" />
                    </el-form-item>
                    <el-form-item label="门店简介" prop="jianjie">
                        <el-input v-model="addForm.jianjie" type="textarea" :rows="3" maxlength="100" show-word-limit style="width:600px"></el-input>
                    </el-form-item>
                    <el-form-item label="操房数量" prop="roomnum">
                        <el-input v-model="addForm.roomnum"  style="width:300px"></el-input> 间
                    </el-form-item>
                    <el-form-item label="门店面积" prop="storearea">
                        <el-input v-model="addForm.storearea"  style="width:300px"></el-input> ㎡
                    </el-form-item>
                    <el-form-item label="器械种类" prop="devicetype">
                        <el-input v-model="addForm.devicetype"  style="width:300px"></el-input> 种
                    </el-form-item>
                    <el-form-item label="客服微信" prop="wechat">
                        <el-input v-model="addForm.wechat"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="wifi" prop="wifi">
                        <el-input v-model="addForm.wifi"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="客服电话" prop="telephone">
                        <el-input v-model="addForm.telephone"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="进店1Sn" prop="sn">
                        <el-input v-model="addForm.sn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="出店1Sn" prop="outsn">
                        <el-input v-model="addForm.outsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="进店2Sn" prop="sn1">
                        <el-input v-model="addForm.sn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="出店2Sn" prop="outsn1">
                        <el-input v-model="addForm.outsn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="鱼眼1Sn" prop="fishsn">
                        <el-input v-model="addForm.fishsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="鱼眼2Sn" prop="fishsn1">
                        <el-input v-model="addForm.fishsn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头1Sn" prop="camera1">
                        <el-input v-model="addForm.camera1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头2Sn" prop="camera2">
                        <el-input v-model="addForm.camera2"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头3Sn" prop="camera3">
                        <el-input v-model="addForm.camera3"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头4Sn" prop="camera4">
                        <el-input v-model="addForm.camera4"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="边缘盒子Sn" prop="boxsn">
                        <el-input v-model="addForm.boxsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="温湿度Sn" prop="wsdsn">
                        <el-input v-model="addForm.wsdsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="isopen">
                        <el-select  v-model="addForm.isopen"  placeholder="请选择状态" style="width:300px">
                            <el-option v-for="item in statuslist" :key="item.id" :label="item.status" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addStore">确 定</el-button>
                    </el-form-item>
                </el-form>
        </el-card>
        
    </div>
</template>
<script>
import TxMap from "../../components/common/TXMap"
import { cities } from '@/assets/js/city.js'
export default {
    components: { TxMap },
    data(){
        return {

            //添加表单数据
            addForm:{
                storename:'',
                city:'',
                address:'东营市广饶县',
                localtion:'',
                prov_name: "",
                city_name: "",
                district_name: "",
                latitude:'',
                longitude:'',
                storepic:'',
                roomnum:'',
                storearea:'',
                devicetype:'',
                wechat:'',
                telephone:'',
                wifi:'',
                jianjie:'',
                isopen:1,
                sn:'',
                outsn:'',
                sn1:'',
                outsn1:'',
                camera1:'',
                camera2:'',
                camera3:'',
                camera4:'',
                fishsn:'',
                fishsn1:'',
                boxsn:'',
                wsdsn:''
                
            },
            //添加表单验证规则
            addFormRules:{
                storename:[
                    { required: true, message: '请选输入门店名称', trigger: 'blur' }
                ],
                city:[
                    { required: true, message: '请选择所属城市', trigger: 'blur' }
                ],
                isopen:[
                    { required: true, message: '请选择店铺状态', trigger: 'blur' }
                ]
            },
            cities:cities,
            citylist:[],
            statuslist:[],
            limitNum: 1,
            fileList:[],
            imgURL:this.GLOBAL.imgURL,
            uploadurl: this.GLOBAL.imgURL+'upload/',
            map: null,
            marker: null
        }
    },
    created () {
        this.getCityList()
        this.getStatusList()
        this.addressChange(15)
    },
    mounted(){
        this.addForm.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    methods:{
        setAddress(newAddress) {
            this.addForm.address =  newAddress.formatted_addresses.recommend; //地址
            this.addForm.latitude = newAddress.location.lat; //纬度
            this.addForm.longitude = newAddress.location.lng; //经度
            this.addForm.prov_name = newAddress.address_component.province; //省
            this.addForm.city_name = newAddress.address_component.city; //市
            this.addForm.district_name = newAddress.address_component.district; //区
            this.addForm = Object.assign({}, this.addForm, {
            address: newAddress.formatted_addresses.recommend,
            });
            // console.log("this.form.store_address", this.form.store_address);
        },
        // 地址搜索事件
      addressChange(zoom = 15) {
        let map_key = "GX3BZ-2XRK4-JIOUX-XP53Z-P2AV3-VIBZU"; //腾讯地图key
        //省市区
        let prov_name = this.addForm.prov_name || "",
          city_name = this.addForm.city_name || "",
          district_name = this.addForm.district_name || "";
        //如果有省市区的选择，地图搜索就要把省市区组装起来，传入到address参数里
        let store_city = prov_name + city_name + district_name;
  
        // 地址转坐标
        this.$jsonp(
          `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&key=${map_key}&address=${store_city}${this.addForm.address}`
        ).then((res) => {
          // 根据点击地点获取经纬度
          let lat = res.result.location.lat; //纬度
          let lng = res.result.location.lng; //经度
          //在地图上定位地点
          this.$refs["map"].init(
            zoom,
            lat,
            lng,
            this.addForm.address
          );
        });
      },
        getCityList(){
            const transformedData = this.cities.map(city => {
                const cityObject = {
                    value: city.name,
                    label: city.name,
                    children: city.districts ? city.districts.map(district => ({
                        value: district,
                        label: district
                    })) : []
                };
                return cityObject;
                
            });
            this.citylist = transformedData
        },
        async getStatusList(){
            const { data: res } = await this.$http.get('status/')
            this.statuslist = res.data
        },
        
        addPic(file, fileList){
            this.addForm.storepic = file.picname
        },
       handleChange(value) {
        console.log(value);
        this.addForm.city = value.join(', ')
        this.addForm.address = value[0]+value[1]
        this.addressChange(15)
      },
      changePic(file, fileList){
        console.log(this.fileList);
      },
      async addStore(){
            console.log(this.addForm)
            
            this.$refs.addFormRef.validate( async valid=>{
            if (!valid) return
            const { data:res } = await this.$http.post('storeadd/',this.addForm)
            if(res.status===0)
            {
                this.$message.success('门店添加成功！')
                this.$router.push({path: '/stores'})
            }
            })
       },
       
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
        
    }
    .el-breadcrumb{
        width: 100%;
    }
    #map {
        width: 100%;
        height: 500px;
    }
    
</style>