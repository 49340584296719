<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>故障信息管理</el-breadcrumb-item>
            <el-breadcrumb-item>故障信息列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-input placeholder="请输入用户名/留言内容" clearable v-model="queryInfo.query" @clear="getList()" style="width: 300px;">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                    <el-select v-model="queryInfo.query1"  placeholder="请选择门店" @change="getList()"  style="margin-left:10px" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query2" clearable  placeholder="状态" @change="getList()"  style="margin-left: 10px">
                        <el-option label="已维修" value="1"></el-option>
                        <el-option label="未维修" value="0"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" :key="list.index" style="width: 100%" stripe size="middle" height="420">

                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="用户" prop="username"  width="150"></el-table-column>
                <el-table-column label="门店" prop="storename"  width="200" show-overflow-tooltip></el-table-column>
                <el-table-column label="描述" prop="content"  width="400" show-overflow-tooltip></el-table-column>
                <el-table-column label="图片" prop="pic"  width="200">
                    <template slot-scope="scope" v-if="scope.row.pic">
                        <el-popover placement="bottom"  width="400" trigger="click">
                            <div style="text-align: center;"><img :src="imgURL_Front+'static/'+scope.row.pic" style="width:380px"></div>
                            <img :src="imgURL_Front+'static/'+scope.row.pic" style="width:30px;height: 30px;" slot="reference">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="发布日期" prop="adddate"  width="200"></el-table-column>
                <el-table-column label="状态" prop="sign"  width="100">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.sign==1" type="success">已维修</el-link>
                        <el-link v-else type="danger">未维修</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
                </el-table>
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {

        return{
            imgURL_Front:this.GLOBAL.imgURL_Front,
            queryInfo: {
                query: '',
                query1:'',
                query2:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            storelist:[],
            admin_token:''
        }
        
    },
    created() {
        //  this.queryInfo.storeid = window.sessionStorage.getItem('storeid_token')
         this.admin_token = window.sessionStorage.getItem('admin_token') 
         this.getList()
         this.GetStore()
    },
    
    methods:{
        async GetStore(){
            const { data: res } = await this.$http.get('store/')
            if(res.status == 0){
                this.storelist = res.data
            }
       },
        async getList() {
            const { data:res } = await this.$http.get('faultslist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async DelInfo(id){
            const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('faultsdel/'+ id,{ params: {admin_token:this.admin_token} })
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getList()
        },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>