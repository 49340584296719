<template>
  <div>
    <download-excel :data = "exclelist" :fields = "excel_fields" name = "收支明细">
        <el-button type="primary" icon="el-icon-upload2" @click="getExcleData">导出Excle</el-button>
    </download-excel>
  </div>
</template>

<script>

export default {
  data() {
      return {
        queryInfo: {
            query: '',
            query1:'',
            startdate:'',
            enddate:'',
            pagenum: 1,
            pagesize: 10
        },
        exclelist:[],
        excel_fields: {  //导出Excel表格的表头设置
            '门店名称': 'storename',
            // '加盟商':'name',
            '内容':'content',
            '收入/支出':'sign',
            '收支金额': 'amount',
            '交易类型': 'type',
            '日期': 'adddate'
        }
      };
  },
  methods: {
    async getExcleData(){
        
        const { data:res } = await this.$http.get('inexpexllist/',{ params: this.queryInfo })
        if(res.status == 0){
            console.log("111111111111");
        //     this.exclelist = res.data
        //     console.log(this.exclelist);
        }
        if(res.status == 1){
            console.log("2222222222222");
        }
    },
  }
};
</script>

<style scoped>
.el-breadcrumb {
  width: 100%;
}
</style>
